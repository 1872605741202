.header{
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
}
.logo{
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  margin-top: -15px;

}
.header-manue{
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.header-manue>li:hover{
  cursor: pointer;
  color: var(--orange);
}

@media screen and (max-width: 768px){
  .header > :nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-manue{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
  .logo{
    width: 3rem;
    height: 3rem;
    margin-left: -15px;
    margin-top: 3px;
  }
}